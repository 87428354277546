<template>
  <div>
    <PageHeader>
      <template slot="title">戻る</template>
      <template slot="content">案件一覧</template>
    </PageHeader>
    <template
      >案件一覧画面です。この画面で〇〇条件で案件を検索できます。</template
    >
    <el-divider></el-divider>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="20" :lg="16" :xl="12">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-input v-model="project_name">
              <template slot="prepend">案件名</template>
            </el-input>
          </el-col>
          <el-col :span="12">
            <el-input v-model="personnel_name">
              <template slot="prepend">担当名</template>
            </el-input>
          </el-col>
        </el-row>
        <br />
        <el-row :gutter="40">
          <el-col :span="12">
            <el-input v-model="customer_name">
              <template slot="prepend">得意先</template>
            </el-input>
          </el-col>
          <el-col :span="12">
            <el-select v-model="created_at_year" clearable placeholder="作成年" style="width:50%">
              <el-option
                v-for="item in years"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="created_at_month"
              clearable
              placeholder="作成月"
              style="width:50%"
            >
              <el-option
                v-for="item in months"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <br />
        <el-row :gutter="40">
          <el-col :span="12">
            <el-button
              class="addBtn"
              type="info"
              @click="createBtn()"
              icon="el-icon-document-add"
              plain
              style="width:100%"
              >案 件 新 規
            </el-button>
          </el-col>
       </el-row>
      </el-col>
    </el-row>


    <el-row type="flex" :gutter="20" justify="left">
      <el-col :span="24">
        <el-divider></el-divider>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-table
          :data="
            table_data
              .filter(
                (data) =>
                  !search ||
                  data.customer_name
                    .toLowerCase()
                    .includes(search.toLowerCase())
              )
              .filter(
                (data) =>
                  !project_name ||
                  data.project_name
                    .toLowerCase()
                    .includes(project_name.toLowerCase())
              )
              .filter(
                (data) =>
                  !customer_name ||
                  data.customer_name
                    .toLowerCase()
                    .includes(customer_name.toLowerCase())
              )
              .filter(
                (data) =>
                  !personnel_name ||
                  data.personnel_name
                    .toLowerCase()
                    .includes(personnel_name.toLowerCase())
              )
              .filter(
                (data) =>
                  !created_at_year ||
                  data.created_at
                    .slice(0, 4)
                    .toLowerCase()
                    .includes(created_at_year.toLowerCase())
              )
              .filter(
                (data) =>
                  !created_at_month ||
                  data.created_at
                    .slice(4, 7)
                    .toLowerCase()
                    .includes(created_at_month.toLowerCase())
              )
          "
          v-loading="loading"
        >
          <template slot="empty">当条件データがありません</template>
          <el-table-column label="案件名" prop="project_name">
            <template slot-scope="scope">
              <a @click="handleEdit(scope.$index, scope.row)" class="rowTxt">{{
                scope.row.project_name
              }}</a>
            </template>
          </el-table-column>
          <el-table-column label="案件詳細" prop="synopsis"> </el-table-column>
          <el-table-column label="得意先" prop="customer_name">
          </el-table-column>
          <el-table-column label="担当者" prop="personnel_name">
          </el-table-column>
          <el-table-column label="作成年月" prop="created_at">
            <!--            截取日期前十位-->
            <template slot-scope="scope">
              {{ scope.row.created_at.toString().substr(0, 7) }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="案件CD" prop="project_code">
          </el-table-column> -->
          <!--          <el-table-column-->
          <!--            label="進歩状態"-->
          <!--            prop="status">-->
          <!--          </el-table-column>-->
          <el-table-column align="right">
            <!--eslint-disable-next-line-->
            <template slot="header" slot-scope="scope">
              <el-input
                v-model="search"
                size="mini"
                placeholder="得意先名で絞ります"
              />
            </template>
            <!--eslint-disable-next-line-->
            <template slot-scope="scope">
              <el-button size="mini" @click="timeLine(scope.$index, scope.row)"
                >TimeLine
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!--    <el-row :gutter="0">-->
    <!--     <el-col :xs="12" :sm="8" :md="6" :lg="4">A</el-col>-->
    <!--     <el-col :xs="12" :sm="8" :md="6" :lg="4">B</el-col>-->
    <!--     <el-col :xs="12" :sm="8" :md="6" :lg="4">C</el-col>-->
    <!--     <el-col :xs="12" :sm="8" :md="6" :lg="4">D</el-col>-->
    <!--     <el-col :xs="12" :sm="8" :md="6" :lg="4">E</el-col>-->
    <!--     <el-col :xs="12" :sm="8" :md="6" :lg="4">F</el-col>-->
    <!--     <el-col :xs="12" :sm="8" :md="6" :lg="4">G</el-col>-->
    <!--    </el-row>-->
    <el-dialog
      title="タイムライン"
      :visible.sync="dialogTimeLineVisible"
      v-if="dialogTimeLineVisible"
      top="5%"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in activities"
          :key="index"
          :icon="icon(activity)"
          :type="activity.type"
          :color="color(activity)"
          :size="large"
          :hollow="activity.hollow"
          :timestamp="activity.change_at"
          placement="top"
        >
          <el-card>
            <h3 v-text="title(activity)"></h3>
            <p v-text="body(activity)"></p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProjectDataAll,
  searchProjectData,
  getTimeLineData,
} from "api/projects";
import PageHeader from "components/common/menu/PageHeader";
import { Message } from "element-ui";

export default {
  name: "ProjectList",
  components: {
    PageHeader,
  },
  data() {
    return {
      activities: [],
      project_name: "",
      customer_name: "",
      personnel_name: "",
      created_at_year: "",
      created_at_month: "",
      dialogTimeLineVisible: false,
      table_data: [],
      loading: false,
      search: "",
      years: [
        {
          value: "2021",
          label: "2021年",
        },
        {
          value: "2022",
          label: "2022年",
        },
        {
          value: "2023",
          label: "2023年",
        },
        {
          value: "2024",
          label: "2024年",
        },
        {
          value: "2025",
          label: "2025年",
        },
        {
          value: "2026",
          label: "2026年",
        },
        {
          value: "2027",
          label: "2027年",
        },
        {
          value: "2028",
          label: "2028年",
        },
        {
          value: "2029",
          label: "2029年",
        },
        {
          value: "2030",
          label: "2030年",
        },
      ],
      months: [
        {
          value: "01",
          label: "一月",
        },
        {
          value: "02",
          label: "二月",
        },
        {
          value: "03",
          label: "三月",
        },
        {
          value: "04",
          label: "四月",
        },
        {
          value: "05",
          label: "五月",
        },
        {
          value: "06",
          label: "六月",
        },
        {
          value: "07",
          label: "七月",
        },
        {
          value: "08",
          label: "八月",
        },
        {
          value: "09",
          label: "九月",
        },
        {
          value: "10",
          label: "十月",
        },
        {
          value: "11",
          label: "十一月",
        },
        {
          value: "12",
          label: "十二月",
        },
      ],
    };
  },
  created: function () {
    this.getProjectDataAll();
  },
  methods: {
    getProjectDataAll() {
      this.loading = true;
      getProjectDataAll()
        .then((res) => {
          if (res.data.status === 1) {
            this.table_data = res.data.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchProjectData() {
      let values = {};
      values["project_code"] = this.ruleForm.project_code;
      values["project_name"] = this.ruleForm.project_name;
      values["customer_name"] = this.ruleForm.customer_name;
      values["is_delete"] = this.ruleForm.is_delete;
      searchProjectData(values)
        .then((res) => {
          if (res.data.status === 1) {
            this.tableData = res.data.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTimeLineData(project_code) {
      let values = {};
      values["project_code"] = project_code;
      getTimeLineData(values)
        .then((res) => {
          if (res.data.status === 1) {
            this.activities = res.data.data;
            this.dialogTimeLineVisible = true;
            console.log(this.activities);
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.searchProjectData();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    createBtn() {
      this.$router.push({ path: "Projectform" });
    },
    handleEdit(index, row) {
      this.$router.push({
        path: "Projectform",
        query: { project_code: row.project_code },
      });
    },
    handleDelete(index, row) {
      console.log(row.project_code);
      this.$router.push({
        path: "Projectform",
        query: { project_code: row.project_code },
      });
    },
    timeLine(index, row) {
      this.getTimeLineData(row.project_code);
    },
    getPdffile() {
      console.log("PDF");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    title(activity) {
      let status = activity.status;
      let changed = activity.changed;
      let type = activity.type;
      switch (status) {
        case "PROJECT":
          status = "案件";
          break;
        case "ESTIMATE":
          status = "見積書";
          break;
        case "ORDER":
          status = "注文書";
          break;
        case "DELIVERY":
          status = "納品書";
          break;
        case "ACCEPTANCE":
          status = "検収書";
          break;
        case "INVOICE":
          status = "請求書";
          break;
        default:
          break;
      }
      switch (type) {
        case "INSERT":
          type = "作成";
          break;
        case "UPDATE":
          type = "更新";
          break;
        default:
          break;
      }
      return status + changed + type;
    },
    body(activity) {
      let change_at = activity.change_at;
      let change_by = activity.change_by;
      let type = activity.type;
      let datestr = change_at.toString();
      switch (activity.type) {
        case "INSERT":
          type = "作成";
          break;
        case "UPDATE":
          type = "更新";
          break;
        default:
          break;
      }
      return (
        change_by +
        "が" +
        datestr.slice(0, 4) +
        "/" +
        datestr.slice(5, 7) +
        "/" +
        datestr.slice(8, 10) +
        " " +
        datestr.slice(11, 13) +
        ":" +
        datestr.slice(14, 16) +
        ":" +
        datestr.slice(17, 19) +
        "に" +
        type
      );
    },
    icon(activity) {
      let icon;
      switch (activity.type) {
        case "INSERT":
          icon = "el-icon-circle-plus-outline";
          break;
        case "UPDATE":
          icon = "el-icon-circle-check";
          break;
        default:
          break;
      }
      return icon;
    },
    color(activity) {
      let color;
      switch (activity.status) {
        case "PROJECT":
          color = "#3F6F8D";
          break;
        case "ESTIMATE":
          color = "#4D2D22";
          break;
        case "ORDER":
          color = "#FE8534";
          break;
        case "DELIVERY":
          color = "#FCCA46";
          break;
        case "ACCEPTANCE":
          color = "#A1C181";
          break;
        case "INVOICE":
          color = "#619B8A";
          break;
        default:
          break;
      }
      return color;
    },
  },
};
</script>

<style>
.el-table .warning-row {
  /* background: oldlace; */
  background-color: rgba(255, 255, 255, 0);
}
.el-table .success-row {
  background-color: rgba(255, 255, 255, 0);
}
</style>
